* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

.spring {
    background-color: rgb(188, 221, 0) !important;
}

.summer {
    background-color: rgb(0, 194, 228) !important;
}

.autumn {
    background-color: rgb(255, 187, 41) !important;
}

.winter {
    background-color: rgb(207, 207, 207) !important;
}

a {
    color: rgb(255, 193, 77);
}

a:hover {
    color: rgb(255, 44, 44);
}

.MuiPaginationItem-root {
    color: sienna;
}

.snow-container {
    position: fixed;
    width: 100vw;
    z-index: 1301;
    pointer-events: none;
    overflow: hidden;
    top: 0;
    height: 100%;
}

.snow {
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation: snow linear infinite;
    animation: snow linear infinite;
}

.snow.foreground {
    background-image: url("./images/snow-medium.png");
    -webkit-animation-duration: 15s;
    animation-duration: 10s;
}

.snow.foreground.layered {
    -webkit-animation-delay: 7.5s;
    animation-delay: 7.5s;
}

.snow.middleground {
    background-image: url("./images/snow-medium.png");
    -webkit-animation-duration: 20s;
    animation-duration: 15s;
}

.snow.middleground.layered {
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
}

.snow.background {
    background-image: url("./images/snow-medium.png");
    -webkit-animation-duration: 25s;
    animation-duration: 20s;
}

.snow.background.layered {
    -webkit-animation-delay: 12.5s;
    animation-delay: 12.5s;
}


/* @-webkit-keyframes snow {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        -webkit-transform: translate3d(5%, 100%, 0);
        transform: translate3d(5%, 100%, 0);
    }
} */

@keyframes snow {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, -100);
    }
    100% {
        -webkit-transform: translate3d(5%, 100%, 0);
        transform: translate3d(-35%, 100%, 0);
    }
}

@keyframes buzz {
    0% {
        color: rgba(212, 212, 212, 0.993);
    }
    100% {
        color: rgb(0, 170, 68);
    }
}

@keyframes toas {
    0% {
        transform: translate3d(0, -50%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes scale {
    0% {
        transform: scale(0.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fromleft {
    0% {
        transform: translate3d(-200px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(45deg, 0deg);
        -ms-transform: rotate3d(0, 0, 0);
        transform: rotate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: rotate(245deg, 360deg);
        -ms-transform: rotate3d(1, 0, 1, 360deg);
        transform: rotate3d(1, 0, 1, 360deg);
    }
}

@keyframes run {
    0% {
        transform: translate(20%, -40%);
        -webkit-transform: translate(20%, 0%);
    }
    100% {
        transform: translate(-35%, 200%);
        -webkit-transform: translate(-35%, 200%);
    }
}

.file-input {
    display: inline-block;
    text-align: left;
    position: relative;
    border-radius: 3px;
}

.file-input>[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

.file-input>.button {
    display: inline-block;
    cursor: pointer;
    margin-right: 8px;
    background: rgba(237, 237, 237, 0.58);
}

.file-input:hover>.button {
    background: dodgerblue;
    color: white;
}

img {
    vertical-align: middle;
    border-style: none;
    width: 100%;
}

.table {
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
    margin: 0;
}

.table table {
    min-width: 600px;
    width: 100%;
    border-spacing: 0;
}

.table td,
.table th {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #4394d9;
    color: #fff;
}


/* .table tr:nth-child(even) {
    background-color: #f0f0f0;
    color: black;
} */

.table tr:hover {
    background-color: #ddd;
    color: black;
}

.table .text__center {
    text-align: center;
}